<template>
  <div>
    <b-card class="card change-supply" header="Vymeniť tovar">
      <b-button @click="onClose" variant="secondary" class="close-button">×</b-button>
      <ul class="list-unstyled mt-3">
        <li v-for="(item, i) in products" :key="'productSwitch'+i">
          <VendorBadge :vendor-id="item.vendor_id"/>
          <div @click="onChangeProduct(item.oid)" class="pointer-event">
            <div class="product-code">
              #{{ item.oid }}
            </div>
            <span class="font-weight-bolder">{{ item.name }}</span>
          </div>
          <div class="product-price">{{ formatPrice(item.price) }} &euro;</div>
        </li>
      </ul>

      <b-button @click="onClose" variant="primary" class="float-right">Zatvoriť</b-button>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapGetters} from "vuex"
import VendorBadge from "@/plugins/app@enums/vendor-badge"
import format from "@/plugins/app/formatters"

export default Vue.extend({
  name: 'switch-product-modal',
  components: {VendorBadge},
  computed: {
    ...mapGetters('recipes', ['ingredientsMap'])
  },
  props: ['id'],
  data: () => ({
    products: []
  }),
  mounted() {
    Object.keys(this.ingredientsMap[this.id]).forEach( productId => {
      this.products.push(this.ingredientsMap[this.id][productId])
    })
  },
  methods: {
    onChangeProduct(oid) {
      this.$root.$emit('switchProduct', {id: this.id, oid })
      this.$emit('close')
    },

    onClose: function() {
      this.$emit('close')
    },

    formatPrice: format.price,
  }
})
</script>

<style lang="scss" scoped>
.close-button {
  color: #C4C4C4;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 23px;
  right: 30px;
  font-size: 24px;
  border: none;
  font-weight: 300;
}
.change-supply{
  .card-header {
    margin-bottom: 0;
  }
  ul{
    border-bottom: 1px solid #F1F1F1;
    li {
      height: 43px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      border-radius: 4px;
      margin-bottom: 5px;
      cursor: pointer;
      &:hover {
        background-color: #E7F9EE;
      }
      .vendor-badge {
        margin-right: 15px;
      }
      .pointer-event {
        font-size: 12px;
      }
      .product-price{
        font-size: 12px;
        flex-grow: 1;
        text-align: right;
        font-weight: 700;
      }
    }
  }
}

  .selected {
    background: #E7F9EE;
  }
</style>
