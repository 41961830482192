<template>
  <div class="container mt-5" v-if="recipe">
    <div class="row m-0">
      <b-card class="col-12 add-recipe-card">
        <b-card-header>
          <div role="group">
            <b-form-group label="Názov receptu" label-for="name" class="mb-0">
              <b-form-input
                  id="name"
                  v-model.trim="$v.recipe.name.$model"
                  size="lg"
                  :state="validateState('name')"
              ></b-form-input>
            </b-form-group>
            <b-form-invalid-feedback id="input-live-feedback">
              Enter at least 3 letters
            </b-form-invalid-feedback>
          </div>
          <ul class="filter-tags list-unstyled d-inline-flex flex-wrap mb-0">
            <li @click="onRemoveFilter('diet', tag.id)" v-for="tag in recipe.diets" :key="`diet-${tag.id}`" :id="`diet-${tag.id}`"
                class="text-nowrap">
              {{ getRecipesSearchFilterName('diet', tag.id) }}
              <b-button variant="link" size="sm"><svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.50564 1.80981L4.68771 3.62774L6.48988 5.4299C6.78004 5.72007 6.77799 6.19258 6.48528 6.48529C6.19258 6.77799 5.72006 6.78005 5.4299 6.48988L3.62773 4.68772L1.80981 6.50565C1.50875 6.75065 1.07319 6.73005 0.801077 6.45794C0.528967 6.18583 0.508366 5.75026 0.753368 5.44921L2.56559 3.63698L0.769082 1.84047C0.526693 1.54153 0.551088 1.10577 0.825578 0.831282C1.10007 0.556792 1.53583 0.532398 1.83476 0.774787L3.63127 2.5713L5.4492 0.753374C5.75026 0.508373 6.18582 0.528973 6.45793 0.801083C6.73004 1.07319 6.75064 1.50876 6.50564 1.80981Z" fill="#990F0F"/>
              </svg></b-button>
            </li>
            <li @click="onRemoveFilter('cuisine', tag.id)" v-for="tag in recipe.cuisines" :key="`cuisine-${tag.id}`" :id="`cuisine-${tag.id}`"
                class="text-nowrap">
              {{ getRecipesSearchFilterName('cuisine', tag.id) }}
              <b-button variant="link" size="sm"><svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.50564 1.80981L4.68771 3.62774L6.48988 5.4299C6.78004 5.72007 6.77799 6.19258 6.48528 6.48529C6.19258 6.77799 5.72006 6.78005 5.4299 6.48988L3.62773 4.68772L1.80981 6.50565C1.50875 6.75065 1.07319 6.73005 0.801077 6.45794C0.528967 6.18583 0.508366 5.75026 0.753368 5.44921L2.56559 3.63698L0.769082 1.84047C0.526693 1.54153 0.551088 1.10577 0.825578 0.831282C1.10007 0.556792 1.53583 0.532398 1.83476 0.774787L3.63127 2.5713L5.4492 0.753374C5.75026 0.508373 6.18582 0.528973 6.45793 0.801083C6.73004 1.07319 6.75064 1.50876 6.50564 1.80981Z" fill="#990F0F"/>
              </svg></b-button>
            </li>
          </ul>
          <b-button v-b-toggle.filter variant="empty" class="add-filter">+ Zaradiť</b-button>
          <b-collapse id="filter">
            <b-card>
              <b-button v-b-toggle.filter class="filter-close">&times;</b-button>
              <div class="container">
                <div class="row m-0">
                  <div class="col-auto col-2p">
                    <h5><strong>Podľa diéty</strong></h5>
                    <b-form-checkbox-group v-model="searchFilters.diet" switches>
                      <template v-for="(switcher, i) in diet">
                        <b-form-checkbox :key="i" switch :value="switcher.id">{{ switcher.name }}</b-form-checkbox>
                      </template>
                    </b-form-checkbox-group>
                  </div>
                  <div class="col-auto col-8p">
                    <h5> <strong>Podľa kuchyne</strong></h5>
                    <b-form-checkbox-group v-model="searchFilters.cuisine" switches>
                      <template v-for="(switcher, i) in cuisine">
                        <b-form-checkbox :key="i" :value="switcher.id">{{ switcher.name }}</b-form-checkbox>
                      </template>
                    </b-form-checkbox-group>
                  </div>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </b-card-header>


        <b-overlay :show="busyUpdate" rounded="lg">


          <b-table striped :ref="'table'" :items="recipe.ingredients" :fields="fields" class="ingredients"
                   v-table-sort="sortableOptions" :tbody-tr-attr="addTrId"
          >
            <template #head(actions)>
              <b-dropdown right class="policy-drop">
                <template #button-content>
                  <span>...</span>
                </template>
                <b-dropdown-item @click.prevent="onRemoveAllIngredients">Vymazať suroviny</b-dropdown-item>
              </b-dropdown>
            </template>



            <template #cell(dragndrop)="value">
              <span class="drop-index" :data-id="value.item.id"><DragNDrop/></span>
            </template>
            <template #cell(name)="value">
                {{ value.value }}
            </template>

            <template #cell(badge)="value">
              <VendorBadge v-if="value.item.product" :vendor-id="value.item.product.vendor_id"/>
            </template>

            <template #cell(tovar)="value">
              <template v-if="value.item.product">
                <div @click="onChangeProduct(value)" class="pointer-event">
                  <div class="product-code">
                    #{{ value.item.product.oid }}
                  </div>
                  <span class="font-weight-bolder">{{ value.item.product.name }}</span>
                </div>
              </template>

            </template>

            <template #cell(price_package)="value">
              <div v-if="value.value">
                {{ formatPrice(value.value) }} &euro;
              </div>
            </template>

            <template #cell(quantity)="value">
              <b-input-group :append="formatUnit(value.item.unit_id)">
                <b-form-input type="number" :value="value.value" :number="true" debounce="500" @change="onChangeQuantity($event, value.item.id)" min="-0" step="1"></b-form-input>
              </b-input-group>
            </template>


            <template #cell(added)="value">
              <template>
                <b-button v-if="!value.value">Do zoznamu</b-button>
                <strong v-else>Pridané</strong>
              </template>
            </template>
            <template #cell(actions)="value">
              <template>
                <b-dropdown right class="policy-drop">
                  <template #button-content>
                    <span>...</span>
                  </template>
                  <b-dropdown-item @click.prevent="onRemoveIngredient(value.item.id)">Vymazať</b-dropdown-item>
                </b-dropdown>
              </template>
            </template>
          </b-table>
          <div ref="shadowTable"></div>

        </b-overlay>
        <b-button block @click="onOpenModal" class="add-recipe">+ Pridať ďalšiu surovinu</b-button>
      </b-card>
    </div>
    <div class="row m-0">
      <b-card class="col-12 mt-4 procedure-steps" header="Technologický postup">
        <ul>
          <li v-for="(step, i) in recipe.procedure_steps" :key="'step'+i" class="d-flex">
            <div class="procedure-number">{{i+1}}</div>
            <b-form-textarea
                :ref="'procedure'"
                id="textarea"
                v-model="recipe.procedure_steps[i]"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
                class="procedure-content"
            ></b-form-textarea>
            <b-button @click="onRemoveProcedureStep(i)" class="remove-step">×</b-button>
          </li>
        </ul>
        <b-button block @click="onAddProcedureStep" class="add-recipe">+ Pridať ďalší krok</b-button>
      </b-card>
    </div>
    <div class="row m-0">
      <b-card class="col-12 mt-4" header="Obrázky">
        <template #header>
          Obrázky
          <div class="float-right">
            <b-button @click="onAddImage">+ Nahrať obrázok</b-button>
          </div>
        </template>
        <vue-dropzone ref="dropzone" id="dropzone"
                      :options="dropzoneOptions"
                      @vdropzone-success="onFinishUpload"
                      @vdropzone-removed-file="onRemovedFile"></vue-dropzone>
      </b-card>
    </div>
    <SubmitFooter v-if="!demoDomain" :on-submit="onSubmit" />
  </div>
</template>

<script>
  import Vue from 'vue'
  import DragNDrop from "@/plugins/app/_layouts/components/drag-n-drop"
  import format from "@/plugins/app/formatters"
  import {mapActions, mapGetters} from "vuex"
  import SearchIngredientsModal from "@/plugins/app/recipes/search-ingredients-modal"
  import SwitchProductModal from "@/plugins/app/recipes/switch-product-modal"
  import wAxios from "@/plugins/w/axios"
  import VendorBadge from "@/plugins/app@enums/vendor-badge"
  import SubmitFooter from "@/plugins/app/_layouts/components/submit-footer"
  import {required} from "vuelidate/lib/validators"


  export default Vue.extend({
    components: {VendorBadge, DragNDrop, SubmitFooter},
    data: function () {
      return {
        sortableOptions: { onEnd: this.onDragEnd, handle: ".drop-index" },
        busyUpdate: false,
        recipe: {
          images: [],
          name: '',
          procedure_steps: [''],
          diets: [],
          cuisines: [],
          price_final: 0,
          ingredients: [],
        },
        searchFilters: {
          diet: [],
          cuisine: []
        },
        fields: [
          { key: 'dragndrop', label: '', class: 'cell-handler'},
          { key: 'name', label: 'Surovina', class: 'cell-name'},
          { key: 'quantity', label: 'Množ. na porciu', class: 'cell-qiantity'},
          { key: 'actions', label: '', class: 'cell-actions'},
        ],
      }
    },
    validations: {
      recipe: {
        name: {required}
      }
    },
    watch: {
      searchFilters: {
        handler: function (filters) {
          this.recipe.diets = filters.diet.map( v => { return {id: v} })
          this.recipe.cuisines = filters.cuisine.map( v => { return {id: v} })
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters('enums', ['cuisine', 'diet']),
      ...mapGetters('wAuth', ['token']),
      ...mapGetters('venues', ['activeVenueId']),
      ...mapGetters('recipes', ['ingredientsMap']),
      demoDomain() {
        return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
      },
      dropzoneOptions: function () {
        return {
          url: !this.demoDomain ? `${process.env.VUE_APP_WAXIOS_URL}/v1/image` : `${process.env.VUE_APP_WAXIOS_URL}/null`,
          thumbnailWidth: 200,
          thumbnailHeight: 200,
          paramName: 'images',
          uploadMultiple: true,
          dictDefaultMessage: '',
          dictRemoveFile: '×',
          addRemoveLinks: true,
          dictCancelUpload: 'Vymazať',
          headers: {
            'Cache-Control': null,
            'X-Requested-With': null,
            'Authorization': `Bearer ${this.token}`,
            'X-Venue-Id': `${this.activeVenueId}`}
        }
      },
    },
    async mounted() {
      const id = this.$route.params.id
      if (this.$route.name !== 'RecipesCreate')
        this.recipe = await wAxios.get_auth_data(`v1/recipe/${id}`)

      if (this.$route.name === 'RecipesDuplicate')
        delete this.recipe.id

      if(this.recipe.images.length)
        this._mountImages()

      if(this.recipe.cuisines.length)
        this.searchFilters.cuisine = this.recipe.cuisines.map( i => i.id)

      if(this.recipe.diets.length)
        this.searchFilters.diet = this.recipe.diets.map( i => i.id)

      if(this.recipe.ingredients.length) {
        await this.preloadIngredients(this.recipe.ingredients.map( e => e.id))
      }

      this.$root.$on('addIngredients', this._addIngredients)
      this.$root.$on('switchProduct', this._switchProduct)
    },
    beforeDestroy() {
      this.$root.$off('addIngredients', this._addIngredients)
      this.$root.$off('switchProduct', this._switchProduct)
    },
    methods: {
      ...mapActions('recipes', ['preloadIngredients']),

      async onDragEnd(event) {
        const newOrder = []
        for (const item of event.target.children) {
          newOrder.push(+item.dataset.id)
        }

        newOrder.forEach((ingredientId, ingredientSort) => {
          // console.log(this.recipe.ingredients)
          this.recipe.ingredients = this.recipe.ingredients.map(v => {
            // console.log(v.id, ingredientId, v.id == ingredientId)
            return v.id == ingredientId
                ? {...v, sort_order: ingredientSort}
                : v
              }
          )
        })
        await this._updateIngredients()
      },

      onAddProcedureStep() {
        this.recipe.procedure_steps.push('')
        this.$nextTick(_ => this.$refs['procedure'][this.recipe.procedure_steps.length - 1].focus())
      },
      onRemoveProcedureStep(index) {
        this.recipe.procedure_steps = this.recipe.procedure_steps.filter((v, i) => i !== index)
      },
      onRemoveIngredient(id) {
        this.recipe.ingredients = this.recipe.ingredients.filter(i => i.id !== id)
      },
      onRemoveAllIngredients() {
        this.recipe.ingredients = []
      },
      onChangeQuantity(portions, id) {
        this.recipe.ingredients.forEach(item => {
          if(item.id === id)
            item.quantity = +portions
        })

        this._updateIngredients()
      },

      onChangeProduct(row) {
        this.$modal.show(SwitchProductModal, {id: row.item.id})
      },

      onAddImage() {
        this.$refs.dropzone.dropzone.hiddenFileInput.click()
      },
      onFinishUpload(file, response) {
        response.data.forEach( item => {
          if(this.recipe.images.filter(v => v.id === item.id).length)
            return

          this.recipe.images.push(item)
        })
      },
      onRemovedFile(file, error, xhr) {
        this.recipe.images = this.recipe.images.filter(e => e.name !== file.name)
      },

      onOpenModal() {
        this.$modal.show(SearchIngredientsModal)
      },
      onRemoveFilter (type, id) {
        this.searchFilters[type] = this.searchFilters[type].filter(e => e !== id)
      },

      async onSubmit() {
        this.$v.recipe.$touch()
        if (this.$v.recipe.$anyError)
          return

        this.$v.recipe.$reset()
        this.$wToast.clear_loading()

        this.recipe.procedure_steps = this.recipe.procedure_steps.filter(item => !!item)

        await wAxios.post_auth_data(`v1/recipe`, this.recipe).then(_ => {
          this.$wToast.success('Recept bol pridaný')
          this.navTo('/app/recipes')
        }).catch(e => this.$wToast.error(e))
      },

      getRecipesSearchFilterName(type, id) {
        const filtered = this[type].filter(e => e.id == id)
        if(!filtered.length)
          return

        return this[type].filter(e => e.id == id)[0].name
      },

      validateState(field) {
        if(!this.$v.recipe[field])
          return null

        const { $dirty, $error } = this.$v.recipe[field]
        return $dirty ? !$error : null
      },

      addTrId(item) {
        return {
          'data-id': item.id
        }
      },

      navTo: function (to) {
        this.$router.push(to)
      },

      async _addIngredients(data) {
        data.selected.forEach(item => {
          if (!this.isQueued(item.id))
            this.recipe.ingredients.push({id: item.id})
        })

        await this._updateIngredients()
      },

      async _switchProduct(data) {
        this.recipe.ingredients.forEach((item, key) => {
          if(item.id == data.id)
            this.recipe.ingredients[key] = {
                id: data.id,
                quantity: item.quantity,
                product: {
                  oid: data.oid
                }
              }

        })
        await this._updateIngredients()
      },


      isQueued(id) {
        return !!this.recipe.ingredients.filter(v => v.id === id).length
      },
      async _updateIngredients() {
        this.busyUpdate = true
        const ingredients = this.recipe.ingredients
        this.$refs.shadowTable.style.height = `${ingredients.length * 50 + (ingredients.length - 1) * 5 + 10}px`
        this.recipe.ingredients = []
        const res = await wAxios.post_auth_data(`v1/ingredient/calculate`, ingredients)
        this.recipe.ingredients = res.ingredients
        this.$forceUpdate()
        this.busyUpdate = false
        this.$refs.shadowTable.style.height = `0px`
      },

      _closeModal(recipeId) {
        this.$refs[`dropdown${recipeId}`].hide()
      },
      _mountImages() {
        const dz = this.$refs.dropzone
        this.recipe.images.forEach(image => {
          dz.manuallyAddFile(image, image.path)
        })
      },
      formatUnit: format.unit,
      formatPrice: format.price,
      formatQuantity: format.quantity,
    }
  })
</script>
<style lang="scss">

.container.mt-5 {
  padding-bottom: 100px;
}

.card{
  &.add-recipe-card{
    .card-header {
      padding: 0;
      .add-filter {
        padding: 17px 0!important;
        &:focus{
          box-shadow: none;
        }
      }
    }
  }
}
.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  border-top: 1px solid #F1F1F1;
  .total-title {
    font-size: 16px;
    font-weight: 500;
  }
  .total-actions{
    > *{
      margin-left: 30px;
    }
    .btn-secondary {
      padding: 8px 10px;
      font-size: 12px;
    }
    .policy-drop{
      margin-left: 20px;
      padding: 8px;
      button {
        background-color: transparent !important;
        border: none;
        &:after {
          display: none;
        }
        span{
          display: block;
          margin-top: -8px;
          color: #A6A6A6;
        }
      }
    }
    .price{
      font-size: 16px;
      font-weight: 500;
      padding-right: 61px;
    }
  }
}
table.ingredients {
  thead {
    tr {
      th{
        vertical-align: middle;
        &.cell-handler{
          width: 0.25%;
        }
        &.cell-name{
          width: 25%;
          .date {
            color: #a6a6a6;
            font-size: 12px;
            padding: 2px 0 2px 7px;
            margin-left: 7px;
            border-left: 1px solid #E7E7E7;
          }
        }
        &.cell-badge{
          color:#A6A6A6;
          font-size: 12px;
          font-weight: 500;
          text-align: right;
          width: 2%;
        }
        &.cell-tovar{
          text-align: center;
          color:#A6A6A6;
          font-size: 12px;
          font-weight: 500;
          width: 40%;
        }
        &.cell-price-packadge{
          text-align: center;
          color:#A6A6A6;
          font-size: 12px;
          font-weight: 500;
          width: 10%;
        }
        &.cell-qiantity{
          color:#A6A6A6;
          font-size: 12px;
          font-weight: 500;
          width: 1%;
          white-space: nowrap;
        }
        &.cell-price-per-portion{
          width: 8%;
          white-space: nowrap;
        }
        &.cell-actions{
          width: 2%;
          text-align: right;
          button{
            background-color: transparent!important;
            border: none;
            &:after{
              display: none;
            }
            &:focus{
              background-color: transparent;
              box-shadow: none;
              &:active{
                box-shadow: none;
              }
            }
            &:active{
              background-color: transparent;
              box-shadow: none;
            }
            span{
              display: block;
              margin-top: -8px;
              color: #A6A6A6;
            }
          }
          .dropdown-menu {
            padding: 9px 5px;

            .dropdown-item {
              padding: 8px 5px;
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      &.edited td{
        background: #fff7de !important;
      }

      border-radius: 4px;
      .tr-indent{
        &:after{
          content:'';
          height: 5px;
          display: block;
        }
      }
      &:nth-of-type(odd){
        background-color: #ffffff;
      }
      td{
        &.cell-handler {
          padding-right: 0;
        }
        &.cell-name {
          font-weight: 500;
        }
        &.cell-badge{
          text-align: right;
        }
        &.cell-tovar{
          .old-price{
            font-size: 10px;
            display: flex;
            align-items: center;
            color: #A6A6A6;
            margin-right: 8px;
          }
          .input-group{
            border: 1px solid #E7E7E7;
            border-radius: 4px;
            flex-wrap: nowrap;
            max-width: 100px;
            margin: 0 auto;
            input{
              text-align: center;
              font-weight: 500;
              &.form-control {
                height: auto;
              }
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            /* Firefox */
            input[type=number] {
              -moz-appearance: textfield;
            }
            button {
              padding: 2px 8px;
              background-color: #F7F7F7;
              border: none ;
            }
            input{
              color: #252525;
              border: none;
              &::placeholder{
                color: #A6A6A6;
              }
            }
            .input-group-append{
              border: none;
              .input-group-text{
                font-size: 12px;
                background-color: #F7F7F7;
                border: none;
              }
            }
          }
        }
        &.cell-price-packadge{
          text-align: right;
        }
        &.cell-qiantity{
          text-align: center;
          font-weight: 500;
          .old-price{
            font-size: 10px;
            display: flex;
            align-items: center;
            color: #A6A6A6;
            margin-right: 8px;
          }
          .input-group{
            border: 1px solid #E7E7E7;
            border-radius: 4px;
            flex-wrap: nowrap;
            max-width: 100px;
            margin: 0 auto;
            input{
              text-align: center;
              font-weight: 500;
              &.form-control {
                height: auto;
              }
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            /* Firefox */
            input[type=number] {
              -moz-appearance: textfield;
            }
            button {
              padding: 2px 8px;
              background-color: #F7F7F7;
              border: none ;
            }
            input{
              color: #252525;
              border: none;
              &::placeholder{
                color: #A6A6A6;
              }
            }
            .input-group-append{
              border: none;
              .input-group-text{
                font-size: 12px;
                background-color: #F7F7F7;
                border: none;
              }
            }
          }
        }
        &.cell-price-per-portion{
          text-align: right;
          white-space: nowrap;
        }
        &.cell-actions{
          text-align: right;
          button{
            background-color: transparent!important;
            border: none;
            &:after{
              display: none;
            }
            &:focus{
              background-color: transparent;
              box-shadow: none;
              &:active{
                box-shadow: none;
              }
            }
            &:active{
              background-color: transparent;
              box-shadow: none;
            }
            span{
              display: block;
              margin-top: -8px;
              color: #A6A6A6;
            }
          }
          .dropdown-menu {
            padding: 9px 5px;

            .dropdown-item {
              padding: 8px 5px;
            }
          }
        }
      }
    }
  }
}
</style>
